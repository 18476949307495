.add-modal ion-card {
  margin: 0;
  box-shadow: none;
}

.add-modal {
  --background: var(--ion-color-content-background);
  --backdrop-opacity: 0.5;

  &::part(content) {
    box-shadow: 0px 0px 10px #000000;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width: auto;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    min-width: 70%;
    width: auto;
  }
}

.w-1000 {
  max-width: 1000px;
}

.h-100 {
  --height: 100px !important;
}

.h-200 {
  --height: 200px !important;
}

.h-250 {
  --height: 250px !important;
}

.h-300 {
  --height: 300px !important;
}

.h-350 {
  --height: 350px !important;
}

.confirm-modal {
  --max-width: 400px;
  --height: 210px;

  .modal-wrapper {
    background: var(--ion-color-content-background) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000066;
    border-radius: 8px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;

    ion-button:only-child {
      margin: 0 auto;
      min-width: 90px;
    }
  }
}

.profile-popover {
  --min-width: 320px;
}

.auto-modal {
  --backdrop-opacity: 0.5;
  background: rgba(15, 14, 26, 0.6);

  &::part(content) {
    height: auto;
    background: #1f1d2c;
    max-width: max-content;
  }
}

.wider-popover {
  --width: 100%;
  --max-width: 400px;
}

.shape-modal {
  --backdrop-opacity: 0.5 !important;
}
