/*
*
*  Client Styles
*
*/
@import 'pages/client/client.page.scss';
/*
*
*  Auth Styles
*
*/
@import 'pages/auth/auth.page.scss';

.grid-overlay {
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(19, 21, 30, 0.8);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s,
    visibility 0.2s;
}

.grid-overlay.show {
  opacity: 1;
  visibility: visible;
}

.fade-in {
  animation: fadeIn 0.2s forwards;
}

.fade-out {
  animation: fadeOut 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
