/*
*
*   CONTENT ATUTH
*
*/

ion-toolbar {
  &#authToolbar {
    ion-title {
      font: normal normal bold 26px/25px Arial;
      letter-spacing: 0px;
      color: #f5f6f9;
      text-transform: uppercase;
    }
  }
}
#authMain {
  --background: #181c28 !important;
  > div {
    height: 100%;
    display: flex;
    ion-card {
      @media (min-width: 768px) {
        width: 400px;
      }
      margin: auto;
      ion-card-header {
        border-bottom: 1px solid var(--ion-color-clientsecondary);
        margin-bottom: 2rem;
      }
      ion-card-title {
        text-align: left;
        font: normal normal bold 30px/36px Arial;
        letter-spacing: 0px;
        color: #ffffff;
      }
      .formStyle {
        .labelForInput {
          text-align: center;
          font: normal normal normal 12px/14px Arial;
          letter-spacing: 0px;
          color: #8d99c8;
          margin-right: 12px;
          width: 4rem;
          white-space: unset;
        }
        ion-input,
        ion-select,
        ion-textarea {
          margin-bottom: 0;
          font: normal normal normal 12px/14px Arial;
        }
        ion-input {
          height: 35px;
        }
        ion-item {
          margin-bottom: 0.2rem;
        }
        ion-button {
          width: 100%;
          --border-radius: 10px;
          margin: 1rem 0;
        }
        .link {
          height: 19px;
          line-height: 3px;
          --min-height: 10px;
          ion-label {
            font: normal normal normal 12px/14px Arial;
            letter-spacing: 0px;
            margin: 0 auto;
            color: var(--ion-color-clientsecondary);
            span {
              font-weight: bold;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
