.StripeElement {
  background-color: inherit;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #37363d;
  transition: none !important;
  margin: 32px 18px 0;
}

.StripeElement--base {
  color: #ffffff !important;
  font-size: 16px;
  letter-spacing: 0.0771992px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.StripeElement--focus {
  border: 1px solid #fff;
}

.StripeElement--invalid {
  border-color: #eb445a;
  color: #eb445a;
}

.StripeElement {
}
