/***

CSS for popovers
**/

ion-item,
ion-textarea,
ion-input,
ion-text,
ion-checkbox,
ion-select,
.ion-input,
.ngInput {
  &.ion-invalid.ion-touched {
    --ion-item-border-color: var(--ion-color-danger-tint) !important;
    --color: var(--ion-color-danger-tint) !important;
    color: var(--ion-color-danger-tint) !important;
    border-color: var(--ion-color-danger-tint) !important;
    --border-color: var(--ion-color-danger-tint) !important;
    color: var(--ion-color-danger-tint) !important;

    ion-label {
      color: var(--ion-color-danger-tint) !important;
      --color: var(--ion-color-danger-tint) !important;
    }

    .input-closed-list-selector {
      border-color: var(--ion-color-danger-tint) !important;
      color: var(--ion-color-danger-tint) !important;
    }
  }

  &.ng-invalid.ng-touched {
    --ion-item-border-color: var(--ion-color-danger-tint) !important;
    --color: var(--ion-color-danger-tint) !important;
    color: var(--ion-color-danger-tint) !important;
    border-color: var(--ion-color-danger-tint) !important;
    --border-color: var(--ion-color-danger-tint) !important;
    color: var(--ion-color-danger-tint) !important;

    ion-label {
      color: var(--ion-color-danger-tint) !important;
      --color: var(--ion-color-danger-tint) !important;
    }

    .input-closed-list-selector {
      border-color: var(--ion-color-danger-tint) !important;
      color: var(--ion-color-danger-tint) !important;
    }
  }

  &.ion-valid.ion-touched {
    --ion-item-border-color: var(--ion-color-success-tint) !important;
    --color: var(--ion-color-success-tint) !important;
    color: var(--ion-color-success-tint) !important;
    border-color: var(--ion-color-success-tint) !important;
    --border-color: var(--ion-color-success-tint) !important;
    color: var(--ion-color-success-tint) !important;

    ion-label {
      color: var(--ion-color-success-tint) !important;
      --color: var(--ion-color-success-tint) !important;
    }

    .input-closed-list-selector {
      border-color: var(--ion-color-success-tint) !important;
      color: var(--ion-color-success-tint) !important;
    }
  }

  &.ng-valid.ng-touched {
    --ion-item-border-color: var(--ion-color-success-tint) !important;
    --color: var(--ion-color-success-tint) !important;
    color: var(--ion-color-success-tint) !important;
    border-color: var(--ion-color-success-tint) !important;
    --border-color: var(--ion-color-success-tint) !important;
    color: var(--ion-color-success-tint) !important;

    ion-label {
      color: var(--ion-color-success-tint) !important;
      --color: var(--ion-color-success-tint) !important;
    }

    .input-closed-list-selector {
      border-color: var(--ion-color-success-tint) !important;
      color: var(--ion-color-success-tint) !important;
    }
  }
}

/*
*
*  Client Styles
*
*/
@import '../app/app.component.scss';

.authModal .modal-wrapper {
  width: 500px;
  border-radius: 15px;
}

.custom-tooltip {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  white-space: nowrap;
  animation: fade 0.3s ease-in;
  font-size: 13px;
  border: 1px solid #000;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
