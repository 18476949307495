/*
*
*   MENU & HEADER
*
*/
ion-split-pane {
  --side-max-width: 220px;
  --side-min-width: 220px;

  /*     &#forAdmin {
      background-color: #181c28 !important;
      --side-max-width: 250px;
      ion-menu {
          ion-content {
              --padding-top: 2rem;
              --padding-bottom: 2rem;
              --padding-end: 0.6rem;
              --padding-start: 0.6rem;
              --background: var(--ion-color-base);
              ion-menu-toggle {
                  ion-button {
                      height: 40px;
                      --background: transparent;
                      border: 1px solid transparent;
                      border-radius: 4px;
                      text-align: left;
                      --box-shadow: none;
                      span {
                          font: normal normal normal 14px/20px Open Sans;
                          letter-spacing: 0px;
                          color: rgba(146, 146, 157, 0.8);
                          text-transform: none;
                          margin-right: auto;
                      }
                      &.active {
                          --background: #3c4d84;
                          border-radius: 4px;
                          span {
                              color: #fff;
                          }
                      }
                  }
                  &:last-child {
                      ion-button {
                          margin-top: 1.6rem !important;
                      }
                  }
              }
              ion-label {
                  text-align: left;
                  font: normal normal normal 10px/20px Open Sans;
                  letter-spacing: 0px;
                  --color: #ffffff;
                  color: #ffffff;
                  opacity: 0.7;
              }
          }
      }
      ion-header {
          height: 80px;
          ion-toolbar {
              height: 80px;
              display: flex;
              ion-select {
                  border: 1px solid rgba(236, 239, 242, 1);
                  border-radius: 8px;
                  opacity: 1;
                  margin: auto 1.5rem;
              }
              .popoverDiv {
                  display: contents;
                  ion-avatar {
                      height: 40px;
                      width: 40px;
                      margin-right: 0.5rem;
                  }
                  ion-label {
                      font: normal normal 600 14px/20px Open Sans;
                      text-transform: uppercase;
                      --color: #696974;
                  }
              }
          }
          .imgApp {
              &:hover {
                  cursor: pointer;
              }
          }
      }
  } */
}

/*
*
*   CONTENT ADMIN
*
*/
.exportButtonsPopover {
  ion-list {
    background: #696974 !important;

    ion-label {
      color: var(--ion-color-light) !important;
    }
  }
}

.noDataLabel {
  /* Neutral/Gray */
  color: var(--ion-color-dark);
  z-index: 1000;
  width: auto;
  height: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  //line-height: 150%;
  /* identical to box height, or 24px */
  letter-spacing: 0.0771992px;
  /* Neutral/Gray */
  transform: translateY(160%) translateX(50px);
}

.noDataImg {
  background-color: var(--ion-color-primary);
  mask: url('/assets/images/create2.svg') no-repeat center;
  height: 100px;
  width: 100px;
  mask-size: contain;
  display: block;
  margin: 15px auto;
}

.noDataButton {
  //--color: var(--ion-color-primary);
}

.mainContent {
  --background: var(--ion-color-content-background);
}

// /* #adminMain {
//     //--background: #181c28 !important;
//     .d-flex {
//         display: flex;
//     }
//     .transparentToolbar {
//         --background: transparent !important;
//     }

//     ion-content {
//         .buttonWithLoading {
//             ion-label {
//                 margin: auto 0.5rem;
//             }
//             ion-spinner {
//                 height: 70%;
//             }
//         }
//         --background: #181c28 !important;
//         .adminContent {
//             min-height: 100%;
//         }
//         ion-card-title {
//             &.pageTitle {
//                 text-align: left !important;
//                 font: normal normal normal 20px/25px Open Sans !important;
//                 letter-spacing: 0px !important;
//                 color: #696974 !important;
//                 text-transform: uppercase !important;
//             }
//         }
//         .formStyle {
//             ion-label {
//                 &.labelInput {
//                     text-align: left;
//                     font: normal normal normal 12px/17px Open Sans;
//                     letter-spacing: 0px;
//                     color: #696974;
//                     padding-left: 1rem;
//                 }
//             }
//             ion-input,
//             ion-select,
//             ion-textarea {
//                 background: #181c28 0% 0% no-repeat padding-box;
//                 border: 1px solid #5c6074;
//                 border-radius: 10px;
//                 margin-bottom: 1rem;
//             }
//             ion-button {
//                 width: 140px;
//                 height: 40px;
//                 text-transform: capitalize;
//                 font: normal normal normal 14px/18px Arial;
//                 box-shadow: 0px 2px 3px #00000033;
//                 border-radius: 10px;
//             }
//         }
//         > ion-card {
//             padding-bottom: 1rem;
//         }

//         rs-paged-list {
//             ion-searchbar {
//                 background: var(--ion-color-clientdark) 0% 0% no-repeat padding-box;

//                 input {
//                     background: var(--ion-color-clientdark) 0% 0% no-repeat padding-box;
//                     border: 1px solid var(--ion-color-clientsecondary);
//                     border-radius: 10px;
//                     font: normal normal normal 16px/22px Open Sans;
//                     letter-spacing: 0px;
//                     color: var(--ion-color-clientsecondary);
//                 }
//                 ion-icon {
//                     top: 8px;
//                     color: var(--ion-color-clientsecondary);
//                 }
//             }
//             ion-select {
//                 background: var(--ion-color-clientdark) 0% 0% no-repeat padding-box;
//                 box-shadow: 0px 2px 2px #1e202333;
//                 border-radius: 10px;
//                 border: 1px solid var(--ion-color-clientsecondary);
//                 font: normal normal normal 14px/25px Arial;
//                 color: var(--ion-color-clientsecondary);
//             }
//             .batch-selector,
//             .page-selector {
//                 ion-label {
//                     text-align: right;
//                     font: normal normal normal 14px/25px Arial;
//                     letter-spacing: 0px;
//                     color: #acacac;
//                 }
//             }
//             ion-buttons {
//                 ion-button {
//                     background: var(--ion-color-clientdark) 0% 0% no-repeat padding-box;
//                     box-shadow: 0px 1px 2px #6d6d6d29;
//                     border-radius: 10px;
//                     ion-label {
//                         text-align: right;
//                         font: normal normal 600 14px/20px Open Sans;
//                         letter-spacing: 0px;
//                         color: var(--ion-color-clientsecondary);
//                         text-transform: uppercase;
//                         margin: 0 6px;
//                     }
//                     ion-icon,
//                     ion-spinner {
//                         font-size: 20px;
//                         height: 20px;
//                         color: var(--ion-color-clientsecondary);
//                         margin-left: 3px;
//                     }
//                 }
//             }
//             .filters-container {
//                 --background: var(--ion-color-clientdark);
//                 ion-button {
//                     background: var(--ion-color-clientdark);
//                 }
//                 ion-item {
//                     --background: var(--ion-color-clientsecondary);
//                 }
//                 border-radius: 10px;
//                 border: 1px solid var(--ion-color-clientsecondary);
//             }
//             .table-row {
//                 background-color: var(--ion-color-clientdark);
//                 --background: var(--ion-color-clientdark);
//                 box-shadow: 0px 1px 1px #66676833;
//                 &:hover {
//                     /*  --background: var(--ion-color-clientsecondary); */
//                     --background: var(--ion-color-clientdarkblue);
//                 }
//                 &.table-headers {
//                     background-color: var(--ion-color-clientdark);
//                     ion-text {
//                         text-align: left;
//                         font: normal normal normal 16px/18px Arial;
//                         letter-spacing: 0px;
//                         color: white;
//                     }
//                 }
//                 &:not(.table-headers) {
//                     background-color: var(--ion-color-clientdark);
//                     box-shadow: 0px 2px 2px #00000033;
//                     border-radius: 10px;

//                     ion-text {
//                         text-align: left;
//                         font: normal normal normal 14px/19px Open Sans;
//                         letter-spacing: 0px;
//                         color: #b2b2b2;
//                     }
//                     /* ion-text:hover {
//                         color: #696974;
//                     } */
//                 }
//                 ion-chip {
//                     border-radius: 5px;
//                     width: 80px;
//                     text-align: center;
//                     ion-label {
//                         text-align: center;
//                         font: normal normal 600 10px/14px Open Sans;
//                         letter-spacing: 0px;
//                         text-transform: uppercase;
//                         margin: auto;
//                     }
//                 }
//             }
//         }
//     }

//     #detailsPage {
//         ion-thumbnail {
//             height: 110px;
//             width: 110px;
//             --border-radius: 10px;
//         }

//         ion-segment {
//             justify-content: start;
//         }
//         ion-label {
//             &.cardTitle {
//                 text-align: left;
//                 font: normal normal normal 22px/26px Arial;
//                 letter-spacing: 0px;
//                 color: #ffffff;
//             }
//             &.subTitle {
//                 text-align: left;
//                 font: normal normal normal 14px/16px Arial;
//                 letter-spacing: 0px;
//                 color: #e1e4e8;
//                 text-transform: uppercase;
//             }
//             &.subTitleDanger {
//                 color: var(--ion-color-danger) !important;
//             }
//             &.labelText {
//                 text-align: left;
//                 font: normal normal normal 12px/14px Arial;
//                 letter-spacing: 0px;
//                 color: #aeb1bf;
//             }
//         }
//         ion-row {
//             &.contentPadding {
//                 padding-left: 16px;
//                 padding-right: 16px;
//                 padding-bottom: 16px;
//             }
//         }
//     }
//     ion-thumbnail {
//         height: 120px;
//         width: 120px;
//         --border-radius: 10px;
//         ion-chip {
//             position: absolute;
//             top: 5.5rem;
//             left: 5rem;
//             background: #0000007a;
//             padding: 7px;
//             .editIcon {
//                 height: 25px;
//                 width: 25px;
//                 margin: 0;
//             }
//         }
//     }

//     ion-card {
//         border-radius: 10px;
//     }

//     ion-title,
//     .titlePage {
//         text-align: left;
//         font: normal normal bold 20px/23px Arial;
//         letter-spacing: 0px;
//         color: #ffffff;
//         text-transform: uppercase;
//     }
// } */
#noFilterHeader {
  #containerHeadersFilters {
    display: none;
  }
}

/*
*
*   MODALS
*
*/
/* ion-label {
    &.cardTitle {
        text-align: left;
        font: normal normal normal 22px/26px Arial;
        letter-spacing: 0px;
        color: #ffffff;
    }
    &.subTitle {
        text-align: left !important;
        font: normal normal normal 14px/16px Arial !important;
        letter-spacing: 0px !important;
        color: #aeb1bf !important;
        text-transform: uppercase !important;
    }
    &.labelText {
        text-align: left !important;
        font: normal normal normal 12px/14px Arial !important;
        letter-spacing: 0px !important;
        color: #aeb1bf !important;
    }
    &.subTitleDanger {
        color: var(--ion-color-danger) !important;
    }
} */

.manageUseCasesModal {
  --width: 400px;
  --height: 400px;

  .modal-wrapper {
    background: #2e3343 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000066;
    border-radius: 15px;
  }

  ion-title {
    font: normal normal normal 20px/23px Arial;
    letter-spacing: 0px;
    color: #ffffff;
  }

  ion-toolbar {
    --background: var(--ion-color-clientdark);
  }

  ::part(scroll) {
    overflow-y: hidden !important;
  }
}

.alertModal {
  --width: 450px;
  /* --height: auto; */
  .modal-wrapper {
    background: #1f1d2c 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000066;
    border-radius: 10px;
    height: 450px;
  }

  ion-title {
    /* Success! */
    height: 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */
    text-align: center;
    letter-spacing: 0.0771992px;
    /* Green/Green */
    color: #3bda8e;
  }

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;
    letter-spacing: 0.0771992px;
    color: #ffffff;
  }

  ion-toolbar {
    --background: var(--ion-color-clientdark);
  }

  ::part(scroll) {
    overflow-y: hidden !important;
  }

  .acceptBtn {
    width: 100%;
    --padding-top: 10px;
    --padding-bottom: 10px;
    height: 40px;
    backdrop-filter: blur(160px);
    --border-radius: 33px;
    margin: 10px 0px;

    ion-label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */
      letter-spacing: 0.0771992px;
      text-transform: uppercase;
      /* Neutral/Black */
      color: #1f1d2c;
    }
  }
}

.clientModalScanExitConfirm {
  --width: 400px;
  --height: 300px;

  .modal-wrapper {
    background: var(--ion-color-black-neutral) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000066;
    border-radius: 8px;
  }
}

.clientModalConfirm {
  --width: 400px;
  --height: 210px;

  .modal-wrapper {
    background: var(--ion-color-content-background) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000066;
    border-radius: 8px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;

    ion-button:only-child {
      margin: 0 auto;
      min-width: 90px;
    }
  }

  /* ion-title {
      font: normal normal normal 20px/23px Arial;
      letter-spacing: 0px;
      color: #ffffff;
  }
  ion-toolbar,
  ion-content {
      --background: var(--ion-color-clientdark);
  }
  ion-content {
      --padding-top: 10px;
  }
  ion-grid,
  ion-row {
      height: 100%;
      ion-col {
          &:first-child {
              margin: auto;
          }
          &:last-child {
              margin-top: auto;
          }
      }
  } */
  /*     .contentModalText {
      font: normal normal normal 17px/20px Arial;
      letter-spacing: 0px;
      color: #aeb1bf;
  } */
  /* ion-button {
      text-transform: capitalize;
      font: normal normal normal 14px/18px Arial;
      box-shadow: 0px 2px 3px #00000033;
      border-radius: 10px;
  } */
}

.clientModalAdd {
  --background: var(--ion-color-content-background);

  .modal-wrapper {
    box-shadow: 0px 0px 10px #000000;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    min-width: 50%;
    width: auto;
    position: absolute;
    right: 0px;
    top: 0px;
    height: calc(100vh - 0px);
    height: 100%;
    transform: none !important;
    offset: 1;
  }

  ion-card {
    margin: 0;
    box-shadow: none;
  }

  ion-content {
    //overflow: overlay;
    //--background: var(--ion-color-clientdark);
  }
}

.bodalWidth40Percent {
  .modal-wrapper {
    min-width: 40%;
    width: auto;
  }
}

.bodalWidth60Percent {
  .modal-wrapper {
    min-width: 60%;
    width: auto;
  }
}

.bodalWidth70Percent {
  .modal-wrapper {
    min-width: 70%;
    width: auto;
  }
}

.newScanConnectionsModal {
  --background: #1f1d2c;

  .modal-wrapper {
    min-width: 40%;
    width: auto;
    background-color: #1f1d2c;
    border-radius: 15px;
  }

  ::part(background) {
    background-color: #1f1d2c;
    --background: #1f1d2c;
  }
}

.selectPictureModal {
  --background: var(--ion-color-clientdark);

  .modal-wrapper {
    box-shadow: 0px 0px 10px #00000066;
    border-radius: 15px;
    width: 50%;
  }

  ion-card {
    margin: 0;
    box-shadow: none;
  }

  ion-content {
    overflow: overlay;
    --background: var(--ion-color-clientdark);
  }
}

.formStyle {
  .input-selection-list {
    margin: 0px 15px;

    .label {
      /* Neutral/Gray */
      color: #878cb1;
      color: var(--ion-color-neutral);
      z-index: 1000;
      width: 100%;
      height: 24px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.0771992px;
      /* transform: scale(0.85); */
      /* display: block; */
      padding: 0px 10px;
      /* padding: 10px 0px; */
    }

    .selection-cont {
    }
  }

  .input-inline-tags {
    margin: 0px 15px;

    .label {
      /* Neutral/Gray */
      color: #878cb1;
      color: var(--ion-color-neutral);
      z-index: 1000;
      width: 100%;
      height: 24px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.0771992px;
      /* transform: scale(0.85); */
      /* display: block; */
      padding: 0px 10px;
      /* padding: 10px 0px; */
    }

    .tags-cont {
      display: flex;
      border: 1px solid transparent;
      background: transparent;
      border-radius: 8px;
      border-color: #37363d;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      padding: 5px 10px;
    }
  }

  .item {
    position: relative;
    margin: 0px 0px;

    .input {
      border: 1px solid transparent;
      --background: transparent;
      border-radius: 8px;
      border-color: #37363d;
      /* height: 110px; */
      --padding-end: 15px;
      --padding-start: 15px;
      display: block;
      --padding-top: 15px;
      --padding-bottom: 15px;

      &.iconLeft {
      }

      &.iconRight {
        --padding-end: 50px;
      }
    }

    .input-textarea {
      border: 1px solid transparent;
      --background: transparent;
      border-radius: 8px;
      border-color: #37363d;
      /* height: 110px; */
      --padding-end: 15px;
      --padding-start: 15px;
      display: block;
      --padding-top: 15px;
      --padding-bottom: 15px;

      &.iconLeft {
      }

      &.iconRight {
        --padding-end: 50px;
      }
    }

    .input-select {
      border: 1px solid transparent;
      --background: transparent;
      background: transparent;
      border-radius: 8px;
      border-color: #37363d;
      /* height: 110px; */
      --padding-end: 15px;
      --padding-start: 15px;
      //display: block;
      --padding-top: 15px;
      --padding-bottom: 15px;

      &::part(icon) {
        margin-bottom: -20px;
      }
    }

    .input-closed-list-selector {
      border: 1px solid transparent;
      background: transparent;
      border-radius: 8px;
      border-color: #37363d;
      padding: 15px 15px;
      //display: block;
      width: 100%;
      cursor: pointer;
    }

    /*         .input-inline-tags {
        width: 100%;
        background-color: rgb(24, 28, 40);
        border: 1px solid rgb(92, 96, 116);
        border-radius: 10px;
        padding: 0px;
    } */
    .label {
      /* Neutral/Gray */
      color: var(--ion-color-neutral);
      z-index: 1000;
      width: auto;
      height: 24px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      //line-height: 150%;
      /* identical to box height, or 24px */
      letter-spacing: 0.0771992px;
      /* Neutral/Gray */
      transform: translateY(160%) translateX(15px);
    }

    &.iconLeft {
      .input {
        --padding-start: 50px;
      }

      .label {
        transform: translateY(160%) translateX(50px);
      }
    }

    &.iconRight {
      .input {
        --padding-end: 50px;
      }
    }

    &.item-has-focus,
    &.item-has-value {
      .label {
        transform: translateY(10%) scale(0.85) translateX(10px);
      }
    }

    .icon {
      width: 50px;
      height: 100%;
      position: absolute;
      z-index: 999;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      /* padding: 13px 0px; */
      &.left {
        left: 0px;
      }

      &.right {
        right: 15px;
      }

      div {
        width: 30px;
        height: 30px;
        background-color: var(--ion-color-neutral);
        margin-top: 5px;
      }

      .iconEmail {
        mask: url('/assets/customIcons/mail.svg') no-repeat center;
      }

      .iconPassword {
        mask: url('/assets/customIcons/lock.svg') no-repeat center;
      }

      .iconAccount {
        mask: url('/assets/customIcons/user.svg') no-repeat center;
      }

      .iconEye {
        mask: url('/assets/customIcons/eye-outline.svg') no-repeat center;
        width: 25px;
      }

      .iconEyeOff {
        mask: url('/assets/customIcons/eye-off-outline.svg') no-repeat center;
        width: 25px;
      }
    }

    &.ion-invalid.ion-touched {
      .iconEmail,
      .iconPassword,
      .iconAccount {
        background-color: var(--ion-color-danger-tint);
      }
    }

    &.ion-valid.ion-touched {
      .iconEmail,
      .iconPassword,
      .iconAccount {
        background-color: var(--ion-color-success-tint);
      }
    }

    .spinner {
      position: absolute;
      right: 20px;
      width: 20px;
      z-index: 1000;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      ion-spinner {
        display: block;
        right: 15px;
        top: 10px;
        --color: var(--ion-color-neutral);
      }
    }

    .spinner-center {
      display: block;
      width: 100%;
      height: 55px;
      text-align: center;

      ion-spinner {
        margin-top: 15px;
        --color: var(--ion-color-neutral);
      }
    }
  }

  /* ion-label {
      &.labelInput {
          text-align: left;
          font: normal normal normal 12px/17px Open Sans;
          letter-spacing: 0px;
          color: #696974;
          padding-left: 1rem;
      }
  }
  ion-input,
  ion-select,
  ion-textarea {
      background: #181c28 0% 0% no-repeat padding-box;
      border: 1px solid #5c6074;
      border-radius: 10px;
      margin-bottom: 1rem;
  }
  ion-button {
      width: 140px;
      height: 40px;
      text-transform: capitalize;
      font: normal normal normal 14px/18px Arial;
      box-shadow: 0px 2px 3px #00000033;
      border-radius: 10px;
  } */
}

ion-button {
  --border-radius: 10px;
}

.collapseContainer {
  div {
    &.expanded {
      max-height: 1000px !important;
      transition: max-height 0.5s;
      transition-timing-function: ease;
      overflow: visible !important;
    }

    &.collapsed {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.5s;
      transition-timing-function: ease;
    }
  }

  .itemExpanded {
    ion-icon {
      transform: rotate(180deg);
      transition: transform 0.5s;
      transition-timing-function: ease;
    }
  }
}

.headerTitle {
  /*
      width: 162px;
      height: 42px;
      left: 47px;
      top: 19px;
  */

  &.clickable {
    cursor: pointer;
  }

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  /* identical to box height, or 42px */

  letter-spacing: 0.114941px;

  /* Neutral/White */

  color: #ffffff;
  padding: 0px 50px;
}

.header-menu-title {
  padding: 40px 20px 20px;
  width: 100%;
}

.menuButton {
  position: absolute;
  left: 87.3%;
  right: 0%;
  top: 9.99%;
  bottom: 87.12%;

  /* Neutral/White */

  background: #ffffff;
}

.menuContainer {
}

.menuContainerBackground {
  --background: rgba(38, 40, 55, 0.6);
  /* Blur */
  backdrop-filter: blur(160px);
  /* Note: backdrop-filter has minimal browser support */
}

.menuContentContainer {
  &::part(scroll) {
    overflow-y: hidden !important;
  }

  .menuBtnContainer {
    overflow: hidden;
    display: block;
    width: 100%;
    margin: 5px 0px 5px auto;
    text-align: left !important;

    &.latest {
      margin-bottom: 36px !important;
    }

    .menuBtn {
      --color: #fff;
      --background: 'transparent';
      --border-radius: 0px;
      --padding-top: 10px;
      --padding-bottom: 10px;
      height: 55px;
      --border-radius: 50px 0px 0px 50px;
      --padding-end: 50px;
      --box-shadow: none;
      text-align: left !important;

      .help {
        img {
          width: 22px;
          margin-left: 10px;
        }

        span {
          margin-left: 10px;
        }
      }

      .icon {
        width: 40px;
        height: 40px;
        background-color: #fff;

        &.shape5 {
          mask: url('/assets/customIcons/shape_5.svg') no-repeat center;
        }

        &.shape6 {
          mask: url('/assets/customIcons/shape_6.svg') no-repeat center;
        }

        &.shape7 {
          mask: url('/assets/customIcons/shape_7.svg') no-repeat center;
        }

        &.shape8 {
          mask: url('/assets/customIcons/shape_8.svg') no-repeat center;
        }

        &.shape9 {
          mask: url('/assets/customIcons/shape_9.svg') no-repeat center;
        }

        &.shape10 {
          mask: url('/assets/customIcons/shape_10.svg') no-repeat center;
        }

        &.shape21 {
          mask: url('/assets/customIcons/shape_21.svg') no-repeat center;
        }
      }

      .text {
        height: 18px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        /* identical to box height, or 18px */
        letter-spacing: 0.0771992px;
        /* Neutral/White */
        color: #ffffff;
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        text-transform: none;
      }

      &.active {
        --background: var(--ion-color-primary);

        .text {
          color: #0f0e1a;
          font-weight: 600;
          font-size: 12px;
        }

        .icon {
          background-color: #000;
        }
      }

      .btnContentWrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 15px 0px;
      }
    }
  }

  .menuSectionTitle {
    padding: 10px 14%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.0771992px;
    color: #878cb1;
    text-transform: uppercase;
    display: block;
  }
}

.contentHeaderToolbar {
  --padding-top: 20px;
  --padding-bottom: 20px;
  --background: var(--ion-color-content-background);

  .popoverDiv {
    display: contents;

    ion-avatar {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }

    ion-label {
      //--color: #878cb1;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 18px */
      letter-spacing: 0.0771992px;
      display: block;
      margin-right: 7px;
    }

    ion-icon {
      //--color: #878cb1;
    }
  }
}

.help-popover {
  display: flex;
}

.vis-hidden {
  visibility: hidden;
  display: block;
  width: 0;
  height: 0;
}

.relative {
  position: relative;

  .alert-mark {
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    background: #df0d00;
    font-size: 10px;
    border: 1px solid #fff;
  }
}
