// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  /*   --ion-color-primary: #8d99c8;
  --ion-color-primary-rgb: 141, 153, 200;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #7c87b0;
  --ion-color-primary-tint: #98a3ce; */
  --ion-color-primary: #5ecbdd;
  --ion-color-primary-rgb: 94, 203, 221;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #53b3c2;
  --ion-color-primary-tint: #6ed0e0;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** neutral **/
  --ion-color-neutral: #878cb1;
  --ion-color-neutral-rgb: 135, 140, 177;
  --ion-color-neutral-contrast: #000000;
  --ion-color-neutral-contrast-rgb: 0, 0, 0;
  --ion-color-neutral-shade: #777b9c;
  --ion-color-neutral-tint: #9398b9;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** clientdark **/
  --ion-color-clientdark: #2e3343;
  --ion-color-clientdark-rgb: 46, 51, 67;
  --ion-color-clientdark-contrast: #ffffff; //#8D99C8;
  --ion-color-clientdark-contrast-rgb: 255, 255, 255; //141,153,200;
  --ion-color-clientdark-shade: #282d3b;
  --ion-color-clientdark-tint: #434756;

  --ion-color-content-background: #1f1d2c;
  --ion-color-content-background-rgb: 30, 30, 30;
  --ion-color-content-background-contrast: #ffffff;
  --ion-color-content-background-contrast-rgb: 255, 255, 255;
  --ion-color-content-background-shade: #1a1a1a;
  --ion-color-content-background-tint: #353535;

  --ion-color-black-neutral: #1f1d2c;
  --ion-color-black-neutral-rgb: 31, 29, 44;
  --ion-color-black-neutral-contrast: #ffffff;
  --ion-color-black-neutral-contrast-rgb: 255, 255, 255;
  --ion-color-black-neutral-shade: #1b1a27;
  --ion-color-black-neutral-tint: #353441;

  .ion-color-clientdark {
    --ion-color-base: var(--ion-color-clientdark);
    --ion-color-base-rgb: var(--ion-color-clientdark-rgb);
    --ion-color-contrast: var(--ion-color-clientdark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-clientdark-contrast-rgb);
    --ion-color-shade: var(--ion-color-clientdark-shade);
    --ion-color-tint: var(--ion-color-clientdark-tint);
  }

  --ion-color-clientsecondary: #878cb1;
  --ion-color-clientsecondary-rgb: 135, 140, 177;
  --ion-color-clientsecondary-contrast: #000000;
  --ion-color-clientsecondary-contrast-rgb: 0, 0, 0;
  --ion-color-clientsecondary-shade: #777b9c;
  --ion-color-clientsecondary-tint: #9398b9;

  .ion-color-clientsecondary {
    --ion-color-base: var(--ion-color-clientsecondary);
    --ion-color-base-rgb: var(--ion-color-clientsecondary-rgb);
    --ion-color-contrast: var(--ion-color-clientsecondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-clientsecondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-clientsecondary-shade);
    --ion-color-tint: var(--ion-color-clientsecondary-tint);
  }

  --ion-color-clientdarkblue: #3c4d84;
  --ion-color-clientdarkblue-rgb: 60, 77, 132;
  --ion-color-clientdarkblue-contrast: #aeb1bf;
  --ion-color-clientdarkblue-contrast-rgb: 174, 177, 191;
  --ion-color-clientdarkblue-shade: #354474;
  --ion-color-clientdarkblue-tint: #505f90;

  .ion-color-clientdarkblue {
    --ion-color-base: var(--ion-color-clientdarkblue);
    --ion-color-base-rgb: var(--ion-color-clientdarkblue-rgb);
    --ion-color-contrast: var(--ion-color-clientdarkblue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-clientdarkblue-contrast-rgb);
    --ion-color-shade: var(--ion-color-clientdarkblue-shade);
    --ion-color-tint: var(--ion-color-clientdarkblue-tint);
  }

  --ion-color-clientorange: #ff9309;
  --ion-color-clientorange-rgb: 255, 147, 9;
  --ion-color-clientorange-contrast: #000000;
  --ion-color-clientorange-contrast-rgb: 0, 0, 0;
  --ion-color-clientorange-shade: #e08108;
  --ion-color-clientorange-tint: #ff9e22;

  .ion-color-clientorange {
    --ion-color-base: var(--ion-color-clientorange);
    --ion-color-base-rgb: var(--ion-color-clientorange-rgb);
    --ion-color-contrast: var(--ion-color-clientorange-contrast);
    --ion-color-contrast-rgb: var(--ion-color-clientorange-contrast-rgb);
    --ion-color-shade: var(--ion-color-clientorange-shade);
    --ion-color-tint: var(--ion-color-clientorange-tint);
  }

  --ion-color-homeprimary: #60d4e7;
  --ion-color-homeprimary-rgb: 96, 212, 231;
  --ion-color-homeprimary-contrast: #000000;
  --ion-color-homeprimary-contrast-rgb: 0, 0, 0;
  --ion-color-homeprimary-shade: #54bbcb;
  --ion-color-homeprimary-tint: #70d8e9;

  .ion-color-homeprimary {
    --ion-color-base: var(--ion-color-homeprimary);
    --ion-color-base-rgb: var(--ion-color-homeprimary-rgb);
    --ion-color-contrast: var(--ion-color-homeprimary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-homeprimary-contrast-rgb);
    --ion-color-shade: var(--ion-color-homeprimary-shade);
    --ion-color-tint: var(--ion-color-homeprimary-tint);
  }
}

/* @media (prefers-color-scheme: dark) { */
/*
   * Dark Colors
   * -------------------------------------------
   */

body {
  --ion-color-primary: #5ecbdd;
  --ion-color-primary-rgb: 94, 203, 221;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #53b3c2;
  --ion-color-primary-tint: #6ed0e0;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;
  --ion-color-success-use-case: #3bda8e;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  /** neutral **/
  --ion-color-neutral: #878cb1;
  --ion-color-neutral-rgb: 135, 140, 177;
  --ion-color-neutral-contrast: #000000;
  --ion-color-neutral-contrast-rgb: 0, 0, 0;
  --ion-color-neutral-shade: #777b9c;
  --ion-color-neutral-tint: #9398b9;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}

/* } */

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-content-background {
  --ion-color-base: var(--ion-color-content-background);
  --ion-color-base-rgb: var(--ion-color-content-background-rgb);
  --ion-color-contrast: var(--ion-color-content-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-content-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-content-background-shade);
  --ion-color-tint: var(--ion-color-content-background-tint);
}

.ion-color-black-neutral {
  --ion-color-base: var(--ion-color-black-neutral);
  --ion-color-base-rgb: var(--ion-color-black-neutral-rgb);
  --ion-color-contrast: var(--ion-color-black-neutral-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-neutral-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-neutral-shade);
  --ion-color-tint: var(--ion-color-black-neutral-tint);
}
