$spacing-values: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 2rem,
);

@mixin generateSpacingUtilities($property, $propertyShort, $sides, $actualSides) {
  @each $modifier, $spacing in $spacing-values {
    @if $sides == 'x' {
      .#{$propertyShort}#{$sides}#{$modifier} {
        #{$property}-left: $spacing !important;
        #{$property}-right: $spacing !important;
      }
    } @else if $sides == 'y' {
      .#{$propertyShort}#{$sides}#{$modifier} {
        #{$property}-top: $spacing !important;
        #{$property}-bottom: $spacing !important;
      }
    } @else if $sides == '' {
      .#{$propertyShort}#{$modifier} {
        #{$property}: $spacing !important;
      }
    } @else {
      .#{$propertyShort}#{$sides}#{$modifier} {
        #{$property}-#{str-slice($actualSides, 1)}: $spacing !important;
      }
    }
  }
}

// Margin utilities
@include generateSpacingUtilities('margin', 'm', '', ''); // All sides
@include generateSpacingUtilities('margin', 'm', 't', 'top'); // Top
@include generateSpacingUtilities('margin', 'm', 'b', 'bottom'); // Bottom
@include generateSpacingUtilities('margin', 'm', 'l', 'left'); // Left
@include generateSpacingUtilities('margin', 'm', 'r', 'right'); // Right
@include generateSpacingUtilities('margin', 'm', 'x', 'x'); // Left and Right
@include generateSpacingUtilities('margin', 'm', 'y', 'y'); // Top and Bottom

// Padding utilities
@include generateSpacingUtilities('padding', 'p', '', ''); // All sides
@include generateSpacingUtilities('padding', 'p', 't', 'top'); // Top
@include generateSpacingUtilities('padding', 'p', 'b', 'bottom'); // Bottom
@include generateSpacingUtilities('padding', 'p', 'l', 'left'); // Left
@include generateSpacingUtilities('padding', 'p', 'r', 'right'); // Right
@include generateSpacingUtilities('padding', 'p', 'x', 'x'); // Left and Right
@include generateSpacingUtilities('padding', 'p', 'y', 'y'); // Top and Bottom
